
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from "vuex";

import DevicesList from '@/components/lists/DevicesList.vue';

import { Location } from '@/entities/Location';

@Component({
  computed: mapGetters([
    'location'
  ]),

  components: {
    DevicesList
  }
})
export default class DevicesListView extends Vue {
  search = "";
  selected = [];

  deleteDialog = false;
  alertDialog = false;
  shellDialog = false;

  alertText = "";
  shellText = "";

  fabMenu = false;

  location!: Location;
}
